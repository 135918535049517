export function responsive(bps: any): any {
  return [bps.default, bps.small, bps.medium, bps.large, bps.xlarge];
}

/**
 * Takes any number of pixels and creates a string with rem units
 * e.g. pxToRem(12, 24) => "1.2rem 2.4rem"
 * @param pixelsArray Any number of pixels
 * @returns String with rem units
 * @deprecated Use `theme.spacing` if needed, otherwise use unitless space values. See
 * https://backpack.outschool.dev/?path=/docs/styling-the-sx-prop--docs#spacing-properties-that-use-the-themespacing-method
 */
export const pxToRem = (...pixelsArray: number[]) =>
  // Divide by 10 because 10px (62.5%) is the base font size.
  pixelsArray.map(pixels => `${pixels / 10}rem`).join(" ");

// Shamelessly copied from shared/helpers.js to use in component library
// Creates IDs like 'sjc44b1w'
// Inspired by http://stackoverflow.com/questions/10985872/nodejs-generate-short-unique-alphanumeric
export function simpleRandomId(): string {
  const nowTimestamp = new Date().getTime();
  return (nowTimestamp + Math.floor(Math.random() * 1000000)).toString(36);
}

export function cleanErrorMessage(message: string): string {
  return message.replace("GraphQL error: ", "");
}

export function stringToPositiveHash(s: string): number {
  let hash = 0;
  for (let i = 0; i < s.length; i++) {
    hash = (hash << 5) - hash + s.charCodeAt(i);
    hash |= 0; // Convert to 32bit integer
  }
  // convert to positive
  return hash & 0xfffffff;
}

// fromEntries polyfill
// source: https://github.com/feross/fromentries/blob/master/index.js
export function fromEntries(iterable: any) {
  return [...iterable].reduce((obj, [key, val]) => {
    obj[key] = val;
    return obj;
  }, {});
}

export function isChunkLoadError(err: any) {
  /*
   * These errors bubble up from different webpack loader plugins.
   *
   * mini-css-extract-plugin: code="CSS_CHUNK_LOAD_FAILED"
   * JSX/TSX files: name="ChunkLoadError"
   */
  return (
    err &&
    (err.name === "ChunkLoadError" || err.code === "CSS_CHUNK_LOAD_FAILED")
  );
}
