import React from "react";
import { styled, SxProps } from "@outschool/backpack";

const CoinIcon = styled("svg")({});

export default function CreditIcon({
  fillColor,
  strokeColor,
  sx,
}: {
  fillColor: string;
  strokeColor: string;
  sx?: SxProps;
}) {
  return (
    <CoinIcon
      viewBox="0 0 24 24"
      fill={`none`}
      xmlns={`http://www.w3.org/2000/svg`}
      sx={sx}
    >
      <circle cx="12" cy="12" r="12" fill={`${fillColor}`} />
      <circle
        cx="12"
        cy="12"
        r="11"
        fill={`${fillColor}`}
        stroke={`${strokeColor}`}
        strokeWidth="2"
      />
      <path
        d="M19 12C19 10.8003 18.6982 9.67114 18.1664 8.68421M12 19C8.13401 19 5 15.866 5 12C5 8.13401 8.13401 5 12 5C13.3197 5 14.554 5.36518 15.6076 6"
        stroke={`${strokeColor}`}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </CoinIcon>
  );
}
