import { CookieKeys } from "@outschool/data-schemas";
import Cookies from "js-cookie";
import React from "react";

import useIpLocation from "../util/useIpLocation";

export enum CookieSettingsMode {
  Enabled = "Enabled",
  Limited = "Limited",
  Off = "Off",
  Unknown = "Unknown",
}

export default function useCookieSettings() {
  const { ipInfoLoaded, isInGDPR } = useIpLocation();
  const [cookiePreferences, setCookiePreferences] = React.useState<
    boolean | undefined
  >(getCookiePreferences());
  const [cookiePreferencesSubmitted, setCookiePreferencesSubmitted] =
    React.useState<boolean>(typeof cookiePreferences !== "undefined");
  const [cookieSettingsLoaded, setCookieSettingsLoaded] =
    React.useState<boolean>(false);
  const [cookieSettingsMode, setCookieSettingsMode] =
    React.useState<CookieSettingsMode>(CookieSettingsMode.Unknown);

  React.useEffect(() => {
    if (cookieSettingsLoaded) {
      return;
    } else if (cookiePreferencesSubmitted) {
      setCookieSettingsLoaded(true);
      setCookieSettingsMode(resolveCookieSettingsMode(cookiePreferences));
    } else if (!ipInfoLoaded) {
      return;
    } else if (!isInGDPR) {
      setCookieSettingsLoaded(true);
    }
  }, [
    cookiePreferences,
    cookiePreferencesSubmitted,
    cookieSettingsLoaded,
    ipInfoLoaded,
    isInGDPR,
  ]);

  const updateCookieSettings = (preferences: boolean) => {
    Cookies.set(CookieKeys.CookiePreferences, String(preferences));
    setCookiePreferences(preferences);
    setCookiePreferencesSubmitted(true);
  };

  return {
    cookiePreferencesSubmitted,
    cookieSettingsLoaded,
    cookieSettingsMode,
    updateCookieSettings,
  };
}

function getCookiePreferences(): boolean | undefined {
  if (typeof window === "undefined") {
    return;
  }

  const preferences = Cookies.get(CookieKeys.CookiePreferences);

  if (typeof preferences === "undefined") {
    return;
  }

  return Boolean(JSON.parse(preferences));
}

/*
 * cookiePreferences are currently stored as a boolean. This may become more
 * granular over time.
 */
export function resolveCookieSettingsMode(
  cookiePreferences: boolean | undefined
): CookieSettingsMode {
  if (cookiePreferences) {
    return CookieSettingsMode.Enabled;
  }

  return CookieSettingsMode.Limited;
}
