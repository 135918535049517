import { Modal, ModalProps } from "@mui/material";
import { faTimes } from "@outschool/icons";
import React from "react";

import { Theme } from "../../theme";
import Box, { BoxProps } from "../Box/Box";
import { ButtonProps } from "../Button/Button";
import { IconButton } from "../IconButton/IconButton";

export { Modal, ModalProps };

export interface ModalContentProps extends BoxProps {}
export const ModalContent = React.forwardRef(
  ({ sx, ...props }: BoxProps, ref: typeof Box) => (
    <Box
      ref={ref}
      sx={[
        (theme: Theme) => ({
          borderRadius: theme.spacing(24),
          backgroundColor: "white",
          overflowY: "auto",
          maxHeight: "95vh",
          width: "100%",
          padding: theme.spacing(24),
          maxWidth: "600px",
          margin: "auto",
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    />
  )
);

export interface ModalCloseButtonProps
  extends Omit<ButtonProps, "aria-label" | "children"> {
  /**
   * Aria label for the close button. Required for accessibility.
   */
  "aria-label": string;
}
export const ModalCloseButton = React.forwardRef(
  (
    { sx, ...props }: ModalCloseButtonProps,
    ref: React.Ref<HTMLButtonElement>
  ) => (
    <IconButton
      ref={ref}
      variant="text"
      sx={[
        (theme: Theme) => ({
          color: "neutral.900",
          width: theme.spacing(46),
          height: theme.spacing(46),
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      icon={faTimes}
      {...props}
    />
  )
);

type BasicModalBaseProps = Omit<ModalProps, "children"> & {
  children?: React.ReactNode;
  modalContentProps?: Omit<ModalContentProps, "children">;
  modalCloseButtonProps?: Omit<ModalCloseButtonProps, "aria-label">;
};

type BasicModalWithCloseButtonProps = BasicModalBaseProps & {
  hasCloseButton: true;
  closeButtonArialLabel: string;
};

type BasicModalWithoutCloseButtonProps = BasicModalBaseProps & {
  hasCloseButton?: never;
  closeButtonArialLabel?: never;
};

export type BasicModalProps =
  | BasicModalWithCloseButtonProps
  | BasicModalWithoutCloseButtonProps;

/**
 * Basic modal composed of a `Modal`, `ModalContent`, and optional `ModalCloseButton`.
 *
 *
 * If `hasCloseButton` is true and `onClose` is provided, a close button will be rendered in the top right corner.
 */
export const BasicModal = ({
  children,
  hasCloseButton,
  onClose,
  closeButtonArialLabel,
  modalContentProps,
  modalCloseButtonProps,
  ...props
}: BasicModalProps) => (
  <Modal onClose={onClose} {...props}>
    <ModalContent
      {...modalContentProps}
      sx={[
        {
          ...(hasCloseButton ? { position: "relative" } : {}),
        },
        ...(modalContentProps?.sx
          ? Array.isArray(modalContentProps?.sx)
            ? modalContentProps?.sx
            : [modalContentProps?.sx]
          : []),
      ]}
    >
      {hasCloseButton && onClose && (
        <ModalCloseButton
          aria-label={closeButtonArialLabel}
          onClick={() => {
            onClose();
            modalCloseButtonProps?.onClick?.();
          }}
          {...modalCloseButtonProps}
          sx={[
            {
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 10,
            },
            ...(modalCloseButtonProps?.sx
              ? Array.isArray(modalCloseButtonProps?.sx)
                ? modalCloseButtonProps?.sx
                : [modalCloseButtonProps?.sx]
              : []),
          ]}
        />
      )}
      {children}
    </ModalContent>
  </Modal>
);
