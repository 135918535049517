import { ComponentType, lazy } from "react";

import { delay } from "./PromiseUtils";
import { isChunkLoadError } from "./utils";

async function retry<T>(
  asyncFn: () => Promise<{ default: ComponentType<T> }>,
  nRetries: number,
  minDelayBetweeenRetries: number
): Promise<{ default: ComponentType<T> }> {
  try {
    return await asyncFn();
  } catch (err) {
    if (isChunkLoadError(err)) {
      if (nRetries > 0) {
        console.log("Retrying lazy load due to chunk error:", err);
        await delay(
          minDelayBetweeenRetries + Math.random() * minDelayBetweeenRetries
        );
        return await retry<T>(asyncFn, nRetries - 1, minDelayBetweeenRetries);
      }
    }
    throw err;
  }
}

export default function lazyWithRetry<T>(
  importFn: () => Promise<{ default: ComponentType<T> }>,
  nRetries = 5,
  minDelayBetweeenRetries = 300
) {
  return lazy<ComponentType<T>>(() =>
    retry(importFn, nRetries, minDelayBetweeenRetries)
  );
}
