import { getSessionId } from "@outschool/attribution";
import get from "lodash/get";
import { Integration } from "../providers/AnalyticsContext";
import { MiddlewareParams } from "../types";

export const AmplitudeSessionReplayMiddleware = ({
  payload,
  next,
}: MiddlewareParams) => {
  const pageUrl = get(payload, "obj.context.page.url");
  if (!pageUrl) {
    next(payload);
  } else {
    const amplitudeIntegration = get(
      payload,
      `obj.integrations.${[Integration.ActionsAmplitude]}`
    );
    if (
      payload.obj.integrations &&
      amplitudeIntegration &&
      amplitudeIntegration.session_id &&
      !!window?.sessionReplay
    ) {
      const storedSessionId = getSessionId() || 0;

      const nextSessionId = amplitudeIntegration.session_id;

      if (storedSessionId < nextSessionId) {
        window.sessionReplay.setSessionId(nextSessionId);
      }
    }
    next(payload);
  }
};
