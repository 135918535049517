import type { AnalyticsConfig } from "./types";

// Imported and re-exported below to fix an issue with tests and spies
import useTrackEvent from "./hooks/useTrackEvent";
import useTrackEventWithSchema from "./hooks/useTrackEventWithSchema";
import { initPerformanceMonitoring } from "./lib/performance";
import pkg, { setPackageState } from "./lib/pkg";

export function init(config: AnalyticsConfig) {
  if (config.env !== "test" && pkg.initialized) {
    throw new Error("@outschool/ui-analytics has already been initialized");
  }

  setPackageState(config);

  if (!!pkg.performance) {
    initPerformanceMonitoring();
  }
}

export { default as AnalyticsLoader } from "./components/AnalyticsLoader";

export { default as useAdSettings } from "./hooks/settings/useAdSettings";
export {
  default as useCookieSettings,
  CookieSettingsMode,
} from "./hooks/settings/useCookieSettings";

export { default as useAnalytics } from "./hooks/useAnalytics";
export { default as useIdentify } from "./hooks/useIdentify";
export { default as useIpLocation } from "./hooks/util/useIpLocation";
export { default as usePageEvent } from "./hooks/usePageEvent";

export { default as useSignup } from "./hooks/account/useSignup";

export { default as useSectionListViewed } from "./hooks/activities/useSectionListViewed";
export { default as useWaitlistSeatClaimedMessageViewed } from "./hooks/activities/useWaitlistSeatClaimedMessageViewed";

export { default as useCheckoutStarted } from "./hooks/ecommerce/useCheckoutStarted";
export { default as useOrderCompleted } from "./hooks/ecommerce/useOrderCompleted";
export { default as usePaymentInfoEntered } from "./hooks/ecommerce/usePaymentInfoEntered";
export { default as useProductAdded } from "./hooks/ecommerce/useProductAdded";
export { default as useProductAddedToWishlist } from "./hooks/ecommerce/useProductAddedToWishlist";
export { default as useProductListViewed } from "./hooks/ecommerce/useProductListViewed";
export { default as useProductsSearched } from "./hooks/ecommerce/useProductsSearched";
export { default as useProductViewed } from "./hooks/ecommerce/useProductViewed";
export { default as useProductClicked } from "./hooks/ecommerce/useProductClicked";
export { default as useOrderRefunded } from "./hooks/ecommerce/useOrderRefunded";

export {
  default as useLogSearchAction,
  LogSearchActionTypes,
} from "./hooks/search/useLogSearchAction";
export { default as useLogSearchImpression } from "./hooks/search/useLogSearchImpression";

export { default as withAdSettings } from "./legacy/withAdSettings";
export { default as withAnalytics } from "./legacy/withAnalytics";
export { default as withIdentify } from "./legacy/withIdentify";
export { default as withTrackEvent } from "./legacy/withTrackEvent";

export {
  PageContextProvider,
  usePageContext,
  PageContext,
} from "./providers/PageContext";

export {
  AnalyticsProvider,
  AnalyticsStatus,
  Integration,
  IntegrationCategory,
  useAnalyticsContext,
} from "./providers/AnalyticsContext";

export { measurePerformance } from "./lib/performance";

export { createPerformanceSnippet, createSegmentSnippet } from "./lib/snippet";

export { getBotRequestInfo, isBotRequest } from "./lib/util";

export type {
  ActivityListing,
  AdSettings,
  AnalyticsConfig,
  AnalyticsInterface,
  EventOptions,
  EventProperties,
  SendIdentify,
  SendTrackEvent,
  Traits,
} from "./types";

export { useTrackEvent, useTrackEventWithSchema };
