import { getReferrerAttribution } from "@outschool/attribution";
import { OAUTH2_IS_NEW_USER } from "@outschool/auth-shared";
import {
  isLocalStorageSupported,
  useLocalStorageReference,
  useLocalStorageState,
} from "@outschool/local-storage";
import { useTranslation } from "@outschool/localization";
import {
  addParamsToUrl,
  browseRootPath,
  joinParentFacebookAccountPath,
  joinParentGoogleAccountPath,
  joinTeacherAccountPath,
  removeParamsFromUrl,
  urlSearchParamsToObject,
} from "@outschool/routes";
import { useTrackEvent } from "@outschool/ui-analytics";
import { useApolloClient } from "@outschool/ui-apollo";
import { useNavigation } from "@outschool/ui-utils";
import Cookies from "js-cookie";
import ldEndsWith from "lodash/endsWith";
import ldIncludes from "lodash/includes";
import ldOmit from "lodash/omit";
import React from "react";

import { currentUserQuery } from "../graphql/CurrentUserQuery";

export const SKIP_LEARNERS_LOCAL_STORAGE_NAME = "os_skip_learners";

let localStorage = isLocalStorageSupported();

const FACEBOOK_LOGIN_STARTED = "FACEBOOK_LOGIN_STARTED";
const FACEBOOK_LOGIN_REDIRECT = "FACEBOOK_LOGIN_REDIRECT";
const GOOGLE_LOGIN_REDIRECT = "GOOGLE_LOGIN_REDIRECT";
const GOOGLE_LOGIN_STARTED = "GOOGLE_LOGIN_STARTED";
const LINE_LOGIN_STARTED = "LINE_LOGIN_STARTED";
const LINE_LOGIN_REDIRECT = "LINE_LOGIN_REDIRECT";
const KAKAO_LOGIN_STARTED = "KAKAO_LOGIN_STARTED";
const KAKAO_LOGIN_REDIRECT = "KAKAO_LOGIN_REDIRECT";
const OAUTH2_STRATEGY = "OAUTH2_STRATEGY";

const removableSignUpParams = [
  "signup",
  "authTrigger",
  "usid",
  "activitySlug", // added this to handle nextjs adding activitySlug in query
  "skipLearners",
  "sectionUid",
  "isTeacherFlow",
  "redirect",
];

export enum AuthTriggerActionTypes {
  SAVE_AFTER_LOGIN = "SavedActivity.SAVE_AFTER_LOGIN",
  SAVE_SEARCH_AFTER_LOGIN = "Webapp.SAVE_SEARCH_AFTER_LOGIN",
  FOLLOW_AFTER_LOGIN = "FollowedUser.FOLLOW_AFTER_LOGIN",
  TEACH_AFTER_LOGIN = "Webapp.TEACH_AFTER_LOGIN",
  MARK_HELPFUL_AFTER_LOGIN = "Webapp.MARK_HELPFUL_AFTER_LOGIN",
}

export type WelcomeModalData = {
  [AuthTriggerActionTypes.SAVE_AFTER_LOGIN]: undefined;
  [AuthTriggerActionTypes.SAVE_SEARCH_AFTER_LOGIN]: undefined;
  [AuthTriggerActionTypes.FOLLOW_AFTER_LOGIN]: {
    leaderName: string;
  };
  [AuthTriggerActionTypes.TEACH_AFTER_LOGIN]: undefined;
  [AuthTriggerActionTypes.MARK_HELPFUL_AFTER_LOGIN]: undefined;
};

type PostLoginAction<T extends AuthTriggerActionTypes = any> = {
  actionType: T;
  isTeacherFlow?: boolean;
  graphqlMutation?: any; //  MutationOptions<any, OperationVariables>;
  welcomeModalData: WelcomeModalData[T];
};

export const APP_STATE_POST_LOGIN_ACTION_LOCAL_STORAGE_NAME =
  "APP_STATE_POST_LOGIN_ACTION";

export const getPostLoginAction = (): PostLoginAction | undefined => {
  const localStorage = isLocalStorageSupported();
  if (localStorage) {
    const postLoginActionString = localStorage.getItem(
      APP_STATE_POST_LOGIN_ACTION_LOCAL_STORAGE_NAME
    );
    if (!!postLoginActionString) {
      return JSON.parse(postLoginActionString);
    }
  }
  return undefined;
};

export const persistPostLoginAction = (postLoginAction: any) => {
  const localStorage = isLocalStorageSupported();
  if (localStorage) {
    localStorage.setItem(
      APP_STATE_POST_LOGIN_ACTION_LOCAL_STORAGE_NAME,
      JSON.stringify(postLoginAction)
    );
  }
};

const clearPostLoginAction = () => {
  const localStorage = isLocalStorageSupported();
  if (localStorage) {
    localStorage.removeItem(APP_STATE_POST_LOGIN_ACTION_LOCAL_STORAGE_NAME);
    localStorage.removeItem(SKIP_LEARNERS_LOCAL_STORAGE_NAME);
  }
};

export const HAS_WEB_USER_LOGGED_IN = "HAS_WEB_USER_LOGGED_IN";
export const POST_LOGIN_PATH_LOCAL_STORAGE_NAME = "POST_LOGIN_PATH";
export const getPostLoginPath = (): string | undefined | null => {
  const localStorage = isLocalStorageSupported();

  // referral postLoginPath
  const { landingPage, usid } = getReferrerAttribution();
  if (usid && landingPage) {
    return landingPage;
  }

  if (localStorage) {
    return localStorage.getItem(POST_LOGIN_PATH_LOCAL_STORAGE_NAME);
  }
  return undefined;
};

export const persistPostLoginPath = (postLoginPath: string) => {
  const localStorage = isLocalStorageSupported();
  if (localStorage) {
    localStorage.setItem(POST_LOGIN_PATH_LOCAL_STORAGE_NAME, postLoginPath);
    localStorage.setItem(HAS_WEB_USER_LOGGED_IN, "true");
  }
};

export const clearPostLoginPath = () => {
  const localStorage = isLocalStorageSupported();
  if (localStorage) {
    localStorage.removeItem(POST_LOGIN_PATH_LOCAL_STORAGE_NAME);
  }
};

export type SupportedStrategies = "google" | "facebook" | "line" | "kakao";

function getStrategyLocalStorageKeys(strategy: SupportedStrategies) {
  switch (strategy) {
    case "google":
      return {
        loginStarted: GOOGLE_LOGIN_STARTED,
        loginRedirect: GOOGLE_LOGIN_REDIRECT,
      };
    case "facebook":
      return {
        loginStarted: FACEBOOK_LOGIN_STARTED,
        loginRedirect: FACEBOOK_LOGIN_REDIRECT,
      };
    case "line":
      return {
        loginStarted: LINE_LOGIN_STARTED,
        loginRedirect: LINE_LOGIN_REDIRECT,
      };
    case "kakao":
      return {
        loginStarted: KAKAO_LOGIN_STARTED,
        loginRedirect: KAKAO_LOGIN_REDIRECT,
      };
    default:
      // TODO: This function is getting called with an undefined strategy.
      // Long-term this should be fixed.
      return {
        loginStarted: FACEBOOK_LOGIN_STARTED,
        loginRedirect: FACEBOOK_LOGIN_REDIRECT,
      };
  }
}

export function setAuthStrategy(
  strategy: SupportedStrategies,
  postLoginAction: any,
  postLoginPath: string
) {
  const { loginStarted, loginRedirect } = getStrategyLocalStorageKeys(strategy);
  if (localStorage) {
    localStorage.setItem(OAUTH2_STRATEGY, strategy);
    if (postLoginAction) {
      // Save the action we should dispatch after logging in
      localStorage.setItem(loginStarted, JSON.stringify(postLoginAction));
    }
    if (postLoginPath) {
      // save original page, this.postLoginPath that the user was browsing to local storage
      // so that we can redirect the user via AppState.redirectAfterLogin()
      localStorage.setItem(loginRedirect, JSON.stringify(postLoginPath));
    }
  }
}

export function resetAuthStrategy(strategy: SupportedStrategies) {
  const { loginStarted, loginRedirect } = getStrategyLocalStorageKeys(strategy);
  localStorage?.removeItem(loginStarted);
  localStorage?.removeItem(loginRedirect);
  localStorage?.removeItem(OAUTH2_STRATEGY);
}

export function getAuthStrategy(chosenStrategy?: any) {
  const strategy =
    localStorage && localStorage.getItem(OAUTH2_STRATEGY)
      ? localStorage.getItem(OAUTH2_STRATEGY)
      : chosenStrategy;
  return { strategy, ...getStrategyLocalStorageKeys(strategy) };
}

export enum AuthTrigger {
  SAVE_CLASS = "save_class",
  SHARE_CLASS = "share_class",
  ENROLL = "enroll",
  MESSAGE_TEACHER = "message_teacher",
  MARK_REVIEW_HELPFUL = "mark_review_helpful",
  SAVE_SEARCH = "save_search",
  REQUEST_CLASS = "request_class",
  REQUEST_TIME = "request_time",
  FOLLOW_TEACHER = "follow_teacher",
  EDIT_TEACHER_PROFILE = "edit_teacher_profile",
  JOIN_OUTSCHOOL = "join_outschool",
  ACTIVATE_OFFER = "activate_offer",
  ACCEPT_GIFT_ENROLLMENT = "accept_gift_enrollment",
  REDEEM_GIFT_CARD = "redeem_gift_card",
  BUY_GIFT_CARD = "buy_gift_card",
  UNKNOWN = "unknown",
  WAITLIST = "waitlist",
  BUY_SUBSCRIPTION = "subscription",
  SEARCH_COUNSELOR = "search_counselor",
}

export function getAuthTrigger(
  postLoginAction: any,
  postLoginPath: string
): AuthTrigger {
  const actionType = postLoginAction?.actionType;
  if (postLoginPath === joinTeacherAccountPath()) {
    return AuthTrigger.EDIT_TEACHER_PROFILE;
  } else if (actionType === AuthTriggerActionTypes.SAVE_AFTER_LOGIN) {
    return AuthTrigger.SAVE_CLASS;
  } else if (actionType === AuthTriggerActionTypes.SAVE_SEARCH_AFTER_LOGIN) {
    return AuthTrigger.SAVE_SEARCH;
  } else if (actionType === AuthTriggerActionTypes.FOLLOW_AFTER_LOGIN) {
    return AuthTrigger.FOLLOW_TEACHER;
  } else if (postLoginPath && ldIncludes(postLoginPath, "/enroll?")) {
    return AuthTrigger.ENROLL;
  } else if (postLoginPath && ldEndsWith(postLoginPath, "/email")) {
    return AuthTrigger.SHARE_CLASS;
  } else if (postLoginPath && ldIncludes(postLoginPath, "modal=classRequest")) {
    return AuthTrigger.REQUEST_CLASS;
  } else if (postLoginPath && ldIncludes(postLoginPath, "openCSR=true")) {
    return AuthTrigger.REQUEST_TIME;
  } else if (postLoginPath && ldIncludes(postLoginPath, "/gift-invite")) {
    return AuthTrigger.ACCEPT_GIFT_ENROLLMENT;
  } else if (postLoginPath && ldIncludes(postLoginPath, "/giftcard/redeem")) {
    return AuthTrigger.REDEEM_GIFT_CARD;
  } else if (postLoginPath && ldIncludes(postLoginPath, "/giftcard/buy")) {
    return AuthTrigger.BUY_GIFT_CARD;
  } else {
    return AuthTrigger.UNKNOWN;
  }
}

export const useGetAuthReason = () => {
  const { t } = useTranslation("ui-auth\\lib\\AuthTrigger");

  return React.useCallback(
    (authTrigger: AuthTrigger): string | null => {
      switch (authTrigger) {
        case AuthTrigger.SAVE_CLASS:
          return t("save this class");
        case AuthTrigger.SHARE_CLASS:
          return t("share this class");
        case AuthTrigger.ENROLL:
          return t("enroll in this class");
        case AuthTrigger.MESSAGE_TEACHER:
          return t("message this teacher");
        case AuthTrigger.MARK_REVIEW_HELPFUL:
          return t("vote on this review");
        case AuthTrigger.SAVE_SEARCH:
          return t("save this search");
        case AuthTrigger.REQUEST_CLASS:
          return t("request a topic");
        case AuthTrigger.REQUEST_TIME:
          return t("request a new time");
        case AuthTrigger.FOLLOW_TEACHER:
          return t("follow this teacher");
        case AuthTrigger.JOIN_OUTSCHOOL:
          return t("join outschool");
        case AuthTrigger.ACTIVATE_OFFER:
          return t("activate this offer");
        case AuthTrigger.ACCEPT_GIFT_ENROLLMENT:
          return t("accept your gift enrollment");
        case AuthTrigger.REDEEM_GIFT_CARD:
          return t("redeem your gift card");
        case AuthTrigger.BUY_GIFT_CARD:
          return t("buy a gift card");
        case AuthTrigger.BUY_SUBSCRIPTION:
          return t("unlock Memberships");
        case AuthTrigger.SEARCH_COUNSELOR:
          return t("continue exploring");
        default:
          return null;
      }
    },
    [t]
  );
};

export const OAUTH2_LOCAL_STORAGE_TRACKING_PARAMS_KEY =
  "OAuth2.trackingParameters";
export function useOAuth2TrackingParams() {
  const { localStorage, canUseLocalStorage } = useLocalStorageReference();
  const [trackingParameters, setTrackingParameters] = useLocalStorageState<
    | {
        [key: string]: string;
      }
    | undefined
  >(OAUTH2_LOCAL_STORAGE_TRACKING_PARAMS_KEY, {});
  const resetTrackingParameters = React.useCallback(() => {
    if (canUseLocalStorage) {
      // @ts-ignore TS(2532): Object is possibly 'undefined'.
      localStorage.removeItem(OAUTH2_LOCAL_STORAGE_TRACKING_PARAMS_KEY);
    }
    setTrackingParameters(undefined);
  }, [localStorage, canUseLocalStorage, setTrackingParameters]);
  return { trackingParameters, setTrackingParameters, resetTrackingParameters };
}

export const LoginFlowContext = React.createContext<{
  authTrigger?: AuthTrigger;
  isSignupModalOpen: boolean;
  enterLoginFlow: <T extends AuthTriggerActionTypes>({
    authTrigger,
    skipLearners,
    postLoginAction,
    newUrl,
    shouldNavigateOverwrite,
  }: {
    authTrigger: AuthTrigger;
    skipLearners?: boolean;
    postLoginAction?: PostLoginAction<T>;
    newUrl?: string;
    shouldNavigateOverwrite?: boolean;
  }) => void;
  exitLoginFlow: () => void;
  handleLoggedIn: () => Promise<void>;
  closeSignupModal: () => void;
  isWelcomeModalOpen: boolean;
  welcomeModalData: WelcomeModalData[AuthTriggerActionTypes];
  closeWelcomeModal: () => void;
  postLoginActionType?: AuthTriggerActionTypes;
  isTeacherFlow: boolean;
  setAllowSignUpModal: (_: boolean) => void;
}>({
  authTrigger: undefined,
  isSignupModalOpen: false,
  enterLoginFlow: () => {},
  exitLoginFlow: () => {},
  handleLoggedIn: () => Promise.resolve(),
  closeSignupModal: () => {},
  isWelcomeModalOpen: false,
  closeWelcomeModal: () => {},
  welcomeModalData: undefined,
  postLoginActionType: undefined,
  isTeacherFlow: false,
  setAllowSignUpModal: () => {},
});

function getPath(newUrl?: string) {
  if (newUrl) {
    return newUrl;
  }
  if (typeof window !== "undefined" && window?.location) {
    return window.location.pathname + window.location.search;
  }
  return null;
}

export const LoginFlowProvider: React.FC<
  React.PropsWithChildren<{
    shouldNavigate?: boolean;
    setHandleLoggedIn?: (handleLoggedIn: () => Promise<void>) => void;
  }>
> = ({ children, shouldNavigate = true, setHandleLoggedIn }) => {
  const localStorage = isLocalStorageSupported();
  const graphqlClient = useApolloClient();
  const trackEvent = useTrackEvent();

  const navigate = useNavigation();
  const [isSignupModalOpen, setIsSignupModalOpen] =
    React.useState<boolean>(false);
  const [allowSignUpModal, setAllowSignUpModal] = React.useState<boolean>(true);
  const [authTrigger, setAuthTrigger] = React.useState<
    AuthTrigger | undefined
  >();
  const [isWelcomeModalOpen, setIsWelcomeModalOpen] =
    React.useState<boolean>(false);
  const [welcomeModalData, setWelcomeModalData] =
    React.useState<WelcomeModalData[AuthTriggerActionTypes]>();
  const [postLoginActionType, setPostLoginActionType] =
    React.useState<AuthTriggerActionTypes>();
  const [isTeacherFlow, setIsTeacherFlow] = React.useState<boolean>(false);

  const closeSignupModal = React.useCallback(() => {
    setIsSignupModalOpen(false);
  }, [setIsSignupModalOpen]);

  const reset = React.useCallback(() => {
    clearPostLoginAction();
    clearPostLoginPath();
    closeSignupModal();
    setAuthTrigger(undefined);
  }, [closeSignupModal, setAuthTrigger]);

  const enterLoginFlow = React.useCallback(
    <T extends AuthTriggerActionTypes>({
      newUrl,
      authTrigger,
      skipLearners,
      postLoginAction,
      shouldNavigateOverwrite = true,
    }: {
      newUrl?: string;
      authTrigger: AuthTrigger;
      skipLearners?: boolean;
      postLoginAction?: PostLoginAction<T>;
      shouldNavigateOverwrite?: boolean;
    }) => {
      clearPostLoginAction();
      const isTeacherFlow = !!postLoginAction?.isTeacherFlow;

      setIsTeacherFlow(isTeacherFlow);

      if (postLoginAction) {
        persistPostLoginAction(postLoginAction);
      }

      const fullPath = getPath(newUrl);

      if (fullPath) {
        const url = addParamsToUrl(fullPath, {
          authTrigger,
          ...(isTeacherFlow ? { isTeacherFlow } : {}),
          ...(skipLearners ? { skipLearners } : {}),
          ...(authTrigger !== AuthTrigger.ENROLL ? { signup: true } : {}),
        });

        const postLoginPath = removeParamsFromUrl(fullPath, [
          "signup",
          "authTrigger",
          "skipLearners",
        ]);

        persistPostLoginPath(postLoginPath);

        /*
         * if `shouldNavigate` is false, then options like `skipLearners` need
         * to be set in local storage instead.
         */
        if (shouldNavigateOverwrite && shouldNavigate) {
          navigate(url);
        } else if (skipLearners && !!localStorage) {
          localStorage.setItem(SKIP_LEARNERS_LOCAL_STORAGE_NAME, "true");
        }

        allowSignUpModal && setIsSignupModalOpen(true);
        setAuthTrigger(authTrigger);
      }
    },
    [
      localStorage,
      navigate,
      setAuthTrigger,
      setIsSignupModalOpen,
      shouldNavigate,
      allowSignUpModal,
    ]
  );

  const exitLoginFlow = React.useCallback(() => {
    let pathnameToRoute = window.location.pathname;
    if (authTrigger === AuthTrigger.ENROLL) {
      // The "enroll" modal is sometimes triggered inside of another modal, or
      // when the user clicks on "show details". Both of these triggers another
      // set of params to the path. If we don't do this here, it will cause a
      // loop in the modal close logic.
      pathnameToRoute = pathnameToRoute.replace("enroll", "");
    }

    reset();
    if (shouldNavigate) {
      navigate(
        addParamsToUrl(
          pathnameToRoute,
          ldOmit(
            urlSearchParamsToObject(window?.location.search),
            removableSignUpParams
          )
        )
      );
    }
  }, [authTrigger, reset, navigate, shouldNavigate]);

  const showOrHideModal = React.useCallback(
    (location: Location) => {
      if (
        location.pathname.includes("/enroll/") ||
        location.pathname.includes("/enroll?")
      ) {
        enterLoginFlow({
          authTrigger: AuthTrigger.ENROLL,
          skipLearners: true,
        });
        return;
      }

      if (new URLSearchParams(location.search).get("signup") === "true") {
        if (new URLSearchParams(location.search).get("authTrigger")) {
          enterLoginFlow({
            authTrigger: new URLSearchParams(location.search).get(
              "authTrigger"
            ) as AuthTrigger,
            skipLearners:
              new URLSearchParams(location.search).get("skipLearners") ===
              "true",
          });
        } else {
          allowSignUpModal && setIsSignupModalOpen(true);
        }

        return;
      }

      setIsSignupModalOpen(false);
    },
    [setIsSignupModalOpen, enterLoginFlow, allowSignUpModal]
  );

  const { trackingParameters, resetTrackingParameters } =
    useOAuth2TrackingParams();

  const handleLoggedIn = React.useCallback(async () => {
    const { strategy } = getAuthStrategy();
    const isNewUser = Boolean(Cookies.get(OAUTH2_IS_NEW_USER));
    Cookies.remove(OAUTH2_IS_NEW_USER);
    if (isNewUser && (strategy === "google" || strategy === "facebook")) {
      // TODO: can't install ui-analytics because it depends on this pkg making it
      // a circular dependecy.
      trackEvent?.(`signup-by-${strategy}`, trackingParameters);
      if (strategy === "facebook") {
        navigate(joinParentFacebookAccountPath());
      } else if (strategy === "google") {
        navigate(joinParentGoogleAccountPath());
      } else {
        console.error("Unknown auth strategy:", strategy);
        navigate(browseRootPath());
      }
      resetTrackingParameters();
      resetAuthStrategy(strategy);
    } else {
      const postLoginAction = getPostLoginAction();
      if (postLoginAction?.graphqlMutation) {
        await graphqlClient.mutate(postLoginAction.graphqlMutation);
        await graphqlClient.query({
          query: currentUserQuery,
          fetchPolicy: "network-only",
        });
      }

      setWelcomeModalData(postLoginAction?.welcomeModalData);
      setIsWelcomeModalOpen(!!postLoginAction?.actionType);
      setPostLoginActionType(postLoginAction?.actionType);
      setIsTeacherFlow(!!postLoginAction?.isTeacherFlow);
      const postLoginPath = getPostLoginPath();
      if (postLoginPath) {
        navigate(postLoginPath);
      }
      reset();
    }
  }, [
    setIsWelcomeModalOpen,
    graphqlClient,
    reset,
    navigate,
    trackEvent,
    resetTrackingParameters,
    trackingParameters,
  ]);

  const closeWelcomeModal = React.useCallback(() => {
    setIsWelcomeModalOpen(false);
    setWelcomeModalData(undefined);
    setPostLoginActionType(undefined);
    setIsTeacherFlow(false);
  }, []);

  // open proper modal on initial page load
  React.useEffect(() => {
    showOrHideModal(window.location);
    // TODO: Enable this lint rule and fix dependencies array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setHandleLoggedIn?.(handleLoggedIn);
  }, [handleLoggedIn, setHandleLoggedIn]);

  return (
    <LoginFlowContext.Provider
      value={{
        isSignupModalOpen,
        enterLoginFlow,
        authTrigger,
        exitLoginFlow,
        closeSignupModal,
        handleLoggedIn,
        isWelcomeModalOpen,
        postLoginActionType,
        closeWelcomeModal,
        welcomeModalData,
        isTeacherFlow,
        setAllowSignUpModal,
      }}
    >
      {children}
    </LoginFlowContext.Provider>
  );
};

export const useLoginFlowContext = () => {
  return React.useContext(LoginFlowContext);
};
