import {
  HOLDOUT,
  sendExperimentTriggerThroughKafkaRestProxy,
} from "@outschool/experiments-shared";
import {
  IntegrationCategory,
  usePageContext,
  useTrackEvent,
} from "@outschool/ui-analytics";
import jsonStringify from "json-stringify-safe";
import React from "react";

import { useServerExperimentContext } from "./ExperimentsServiceProvider";

export default function useExperiment({
  currentUserHasLoaded,
  experimentName,
  defaultVariant = HOLDOUT,
}: {
  currentUserHasLoaded: boolean;
  experimentName: string;
  defaultVariant?: string;
}) {
  const page = usePageContext();

  const trackEvent = useTrackEvent(IntegrationCategory.Functional);

  const {
    eventSource,
    loggedInUserExperimentUid,
    loggedOutUserExperimentUid,
    experimentAssignments = {},
    experimentOverrides = {},
    shouldTrigger,
    isExperimentOverridesEnabled,
    isProduction,
  } = useServerExperimentContext();
  const hasOverride =
    isExperimentOverridesEnabled &&
    experimentOverrides.hasOwnProperty(experimentName);
  const hasExperiment = experimentAssignments.hasOwnProperty(experimentName);
  const variant =
    (hasOverride
      ? experimentOverrides[experimentName]
      : experimentAssignments[experimentName]) ?? defaultVariant;
  // TODO: This is returning different variants CSR vs SSR, figure out why.

  /**
    Trigger should be called as close to the inflection point at which the experiment applies.
    For example:
      If running an experiment where "clicking on class listings opens them in a new tab"
      Then you should call trigger once the class listing has been clicked, as that is the point
        at which the experience diverges
    Another example:
      If running an experiment where you change the layout of the not-enrolled class details page
      Then you should call trigger once there is an impression on the class details page on one
        of the elements you have changed
  */
  const trigger = React.useCallback(
    ({
      additionalProperties,
    }: { additionalProperties?: Record<any, any> } = {}) => {
      if (
        currentUserHasLoaded &&
        ((isProduction && hasExperiment && !hasOverride) ||
          (!isProduction && (hasExperiment || hasOverride)))
      ) {
        if (
          shouldTrigger({
            experimentName,
            variantAssigned: variant,
            additionalProperties,
          })
        ) {
          sendExperimentTriggerThroughKafkaRestProxy(
            {
              experiment_name: experimentName,
              variant_assigned: variant,
              logged_out_user_experiment_uid: loggedOutUserExperimentUid,
              logged_in_user_experiment_uid: loggedInUserExperimentUid,
              page_category: page.pageCategory,
              page_name: page.pageName,
              user_id: loggedInUserExperimentUid,
              additional_properties: jsonStringify(additionalProperties),
            },
            eventSource
          ).then(() => {
            trackEvent("Experiment Triggered", {
              experiment_name: experimentName,
              experiment_variant: variant,
            });
          });
        }
      }
    },
    [
      currentUserHasLoaded,
      variant,
      experimentName,
      hasExperiment,
      hasOverride,
      loggedInUserExperimentUid,
      shouldTrigger,
      loggedOutUserExperimentUid,
      page,
      eventSource,
      isProduction,
      trackEvent,
    ]
  );

  return {
    variant,
    trigger,
  };
}
