import { useTranslation } from "@outschool/localization";
import {
  LegacyImage,
  LegacyImageProps,
} from "@outschool/ui-legacy-component-library";
import React from "react";

export const DEFAULT_AVATAR = "star";
export const COMMON_AVATARS = [
  "star", // Default Avatar
  "alien",
  "apple",
  "banana",
  "baseball",
  "basketball",
  "beaker",
  "boat",
  "book",
  "camera",
  "cat",
  "cloud",
  "dino",
  "dog",
  "donut",
  "face1",
  "face10",
  "face12",
  "face13",
  "face14",
  "face15",
  "face2",
  "face3",
  "face4",
  "face5",
  "face6",
  "face7",
  "face8",
  "face9",
  "fish",
  "flower",
  "hat",
  "hummingbird",
  "koala",
  "mug",
  "owl",
  "planet",
  "rubix",
  "sun",
  "unicorn",
];

async function avatarImageUrl(name: string) {
  const { default: imageUrl } = await import(
    `../../images/avatars/${name}.png`
  );
  return imageUrl;
}

interface AvatarImageProps extends LegacyImageProps {
  avatar: string | null;
  name?: string | null;
}

export default function AvatarImage({
  avatar,
  name = "",
  ...other
}: AvatarImageProps) {
  const { t } = useTranslation("ui-components-shared\\AvatarImage");
  const [avatarSrc, setAvatarSrc] = React.useState(null);
  React.useEffect(() => {
    const fetchAvatar = async () => {
      if (avatar) {
        setAvatarSrc(await avatarImageUrl(avatar));
      }
    };
    fetchAvatar();
  }, [avatar]);
  if (!avatarSrc) {
    return null;
  }
  let alt = name;
  if (name) {
    alt = t("Avatar for {{name}}", { name });
  }
  return (
    <LegacyImage
      className="amp-block"
      src={avatarSrc}
      alt={alt ?? undefined}
      {...other}
    />
  );
}
