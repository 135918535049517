import { BaseRoutes, makeUrlFunction } from "./baseRoutes";
import { makeUrlFromLearnerPathFn } from "./config";
import {
  appleAuthRedirectPath,
  clubPath,
  clubPostCommentPath,
  clubPostPath,
  facebookAuthRedirectPath,
  googleAuthRedirectPath,
  oidcAuthLoginRedirectPath,
  oidcAuthLoginRedirectToProviderPath,
  recordingPath,
} from "./shared";
import {
  ActivitySlugProps,
  RouteConfigOptions,
  UrlParams,
  activitySlug,
  addParamsToUrl,
  getLearnerHostForEnv,
} from "./utils";

/**
 * Provides paths and URLs to resources in the learner app.
 *
 * We are abandoning this OO pattern in favor of stand-alone functions to
 * support tree-shaking. New functions do not need to be added to the class.
 *
 */
export class LearnerRoutes extends BaseRoutes {
  constructor(config: RouteConfigOptions) {
    super(getLearnerHostForEnv(config.appHostName), config.locale);
  }

  public static classroomPath = learnerClassroomPath;
  public classroomPath = learnerClassroomPath;
  public classroomUrl = makeUrlFunction(learnerClassroomPath);

  public static classroomSinglePostPath = classroomSinglePostPath;
  public classroomSinglePostPath = classroomSinglePostPath;
  public classroomSinglePostUrl = makeUrlFunction(classroomSinglePostPath);

  public static clubPath = clubPath;
  public clubPath = clubPath;
  public clubUrl = makeUrlFunction(clubPath);

  public static clubPostPath = clubPostPath;
  public clubPostPath = clubPostPath;
  public clubPostUrl = makeUrlFunction(clubPostPath);

  public static loginPath = learnerLoginPath;
  public loginPath = learnerLoginPath;
  public loginUrl = makeUrlFunction(learnerLoginPath);

  public static reportIssuePath = reportIssuePath;
  public reportIssuePath = reportIssuePath;
  public reportIssueUrl = makeUrlFunction(reportIssuePath);

  public static clubPostCommentPath = clubPostCommentPath;
  public clubPostCommentPath = clubPostCommentPath;
  public clubPostCommentUrl = makeUrlFunction(clubPostCommentPath);

  public static authCodeTransferPath = authCodeTransferPath;
  public authCodeTransferPath = authCodeTransferPath;

  public static activityPath = activityPath;
  public activityPath = activityPath;
  public activityUrl = makeUrlFunction(activityPath);

  public static homePath = homePath;
  public homePath = homePath;
  public homeUrl = makeUrlFunction(homePath);

  public static findClassesPath = learnerFindClassesPath;
  public findClassesPath = learnerFindClassesPath;
  public findClassesUrl = makeUrlFunction(learnerFindClassesPath);

  public static calendarViewPath = calendarViewPath;
  public calendarViewPath = calendarViewPath;
  public calendarViewUrl = makeUrlFunction(calendarViewPath);

  public static dashboardPath = dashboardPath;
  public dashboardPath = dashboardPath;
  public dashboardUrl = makeUrlFunction(dashboardPath);

  public static recommendedClassesPath = recommendedClassesPath;
  public recommendedClassesPath = recommendedClassesPath;
  public recommendedClassesUrl = makeUrlFunction(recommendedClassesPath);

  public static topicSearchResultsPath = topicSearchResultsPath;
  public topicSearchResultsPath = topicSearchResultsPath;
  public topicSearchResultsUrl = makeUrlFunction(topicSearchResultsPath);

  public static keywordSearchResultsPath = keywordSearchResultsPath;
  public keywordSearchResultsPath = keywordSearchResultsPath;
  public keywordSearchResultsUrl = makeUrlFunction(keywordSearchResultsPath);

  public static teacherSearchResultPath = teacherSearchResultPath;
  public teacherSearchResultPath = teacherSearchResultPath;
  public teacherSearchResultUrl = makeUrlFunction(teacherSearchResultPath);

  public static teacherClassesPath = teacherClassesPath;
  public teacherClassesPath = teacherClassesPath;
  public teacherClassesUrl = makeUrlFunction(teacherClassesPath);

  public static dashboardSchedulePath = dashboardSchedulePath;
  public dashboardSchedulePath = dashboardSchedulePath;
  public dashboardScheduleUrl = makeUrlFunction(dashboardSchedulePath);

  public static sectionJoinPath = sectionJoinPath;
  public sectionJoinPath = sectionJoinPath;
  public sectionJoinUrl = makeUrlFunction(sectionJoinPath);

  public static lessonsPath = lessonsPath;
  public lessonsPath = lessonsPath;
  public lessonsUrl = makeUrlFunction(lessonsPath);

  public static assignmentsPath = assignmentsPath;
  public assignmentsPath = assignmentsPath;
  public assignmentsUrl = makeUrlFunction(assignmentsPath);

  public static privateClassMessagesPath = privateClassMessagesPath;
  public privateClassMessagesPath = privateClassMessagesPath;
  public privateClassMessagesUrl = makeUrlFunction(privateClassMessagesPath);

  public static recordingPath = recordingPath;
  public recordingPath = recordingPath;
  public recordingUrl = makeUrlFunction(recordingPath);

  public static savedClassesPath = learnerSavedClassesPath;
  public savedClassesPath = learnerSavedClassesPath;
  public savedClassesUrl = makeUrlFunction(learnerSavedClassesPath);

  public static changePasswordPath = learnerChangePasswordPath;
  public changePasswordPath = learnerChangePasswordPath;
  public changePasswordUrl = makeUrlFunction(learnerChangePasswordPath);

  public static signUpPath = signUpPath;
  public signUpPath = signUpPath;
  public signUpUrl = makeUrlFunction(signUpPath);

  public static profilePath = profilePath;
  public profilePath = profilePath;
  public profileUrl = makeUrlFunction(profilePath);

  public static welcomePath = welcomePath;
  public welcomePath = welcomePath;
  public welcomeUrl = makeUrlFunction(welcomePath);

  public static settingsPath = settingsPath;
  public settingsPath = settingsPath;
  public settingsUrl = makeUrlFunction(settingsPath);

  public static privateClubMessagesPath = privateClubMessagesPath;
  public privateClubMessagesPath = privateClubMessagesPath;
  public privateClubMessagesUrl = makeUrlFunction(privateClubMessagesPath);

  public static redirectAfterLoginPath = redirectAfterLoginPath;
  public redirectAfterLoginPath = redirectAfterLoginPath;
  public redirectAfterLoginUrl = makeUrlFunction(redirectAfterLoginPath);
}

export { clubPath, clubPostPath, clubPostCommentPath } from "./shared";

export function learnerClassroomPath(
  sectionUid: string,
  enrollmentUid?: string
) {
  return addParamsToUrl(`/classroom/${sectionUid}`, {
    enrollmentUid,
  });
}

export function classroomSinglePostPath(
  sectionUid: string,
  classPostUid: string,
  enrollmentUid?: string | undefined
) {
  return addParamsToUrl(`/classroom/${sectionUid}/posts/${classPostUid}`, {
    enrollmentUid,
  });
}

export function learnerLoginPath(redirect?: string) {
  return addParamsToUrl("/users/login", { redirect });
}

export function reportIssuePath() {
  return "/report-issue";
}

// TODO: (TI-32) dedupe authCodeTransferPath & learnerAuthCodeTransferPath
export function authCodeTransferPath({
  transferToken,
  learnerAppUrl,
  isLearnerLogin,
}: {
  transferToken: string;
  learnerAppUrl?: string;
  isLearnerLogin?: boolean;
}) {
  const queryParams: UrlParams = {
    learnerAppUrl,
  };
  if (isLearnerLogin) {
    queryParams.ll = "t";
  }
  return addParamsToUrl(`/auth-code/${transferToken}`, queryParams);
}

export function learnerAuthCodeTransferPath({
  transferToken,
  learnerAppUrl,
  isLearnerLogin,
}: {
  transferToken: string;
  learnerAppUrl?: string;
  isLearnerLogin?: boolean;
}) {
  const queryParams: UrlParams = {
    learnerAppUrl,
  };
  if (isLearnerLogin) {
    queryParams.ll = "t";
  }
  return addParamsToUrl(`/auth-code/${transferToken}`, queryParams);
}

export const learnerAuthCodeTransferUrl = makeUrlFromLearnerPathFn(
  learnerAuthCodeTransferPath
);

function activityPath(activity: ActivitySlugProps) {
  return `/activity/${activitySlug(activity)}`;
}

export function homePath() {
  return "/";
}

export function learnerFindClassesPath() {
  return "/find-classes";
}

export function calendarViewPath() {
  return "/dashboard/calendar";
}

export function dashboardPath() {
  return "/dashboard/all";
}

export function recommendedClassesPath() {
  return `${learnerFindClassesPath()}/recommended`;
}

export function topicSearchResultsPath(topicUid: string) {
  return `${learnerFindClassesPath()}/topic/${topicUid}`;
}

export function keywordSearchResultsPath(keyword: string) {
  return `${learnerFindClassesPath()}/keyword/${encodeURIComponent(keyword)}`;
}

export function teacherSearchResultPath(leaderLink: string) {
  return `${learnerFindClassesPath()}/teacher/${leaderLink}`;
}

export function teacherClassesPath(leaderLink: string) {
  return `${teacherSearchResultPath(leaderLink)}/classes`;
}

export function dashboardSchedulePath() {
  return "/dashboard/schedule";
}

export function lessonsPath(sectionUid: string, lessonUid?: string) {
  return `/classroom/${sectionUid}/lessons${lessonUid ? `/${lessonUid}` : ""}`;
}

export function assignmentsPath(sectionUid: string) {
  return `/classroom/${sectionUid}/assignments`;
}

export function sectionJoinPath(
  section: { uid: string },
  enrollment: { uid: string }
) {
  return `/classroom/${section?.uid}/join/${enrollment?.uid}`;
}

export function privateClassMessagesPath(sectionUid: string) {
  return `/classroom/${sectionUid}/private-class-messages`;
}

export function learnerSavedClassesPath() {
  return "/saved-classes";
}

export function learnerChangePasswordPath() {
  return "/users/change-password";
}

export function signUpPath() {
  return "/join/account";
}

export function profilePath({ uid: learnerUid }: { uid: string }) {
  return `/profile/${learnerUid}`;
}

export function welcomePath(signupEmail?: string) {
  if (signupEmail) {
    return `/welcome?signup_email=${encodeURIComponent(signupEmail)}`;
  }
  return `/welcome`;
}

export function settingsPath() {
  return `/settings`;
}

interface PrivateClubMessagesPathArgs {
  sectionUid: string;
}

export function privateClubMessagesPath({
  sectionUid,
}: PrivateClubMessagesPathArgs) {
  return `/group/${sectionUid}/private-group-messages`;
}

function redirectAfterLoginPath(postLoginPath: string) {
  return `/users/redirect-after-login?redirect=${postLoginPath}`;
}

export const learnerGoogleAuthRedirectUrl = makeUrlFromLearnerPathFn(
  googleAuthRedirectPath
);

export const learnerFacebookAuthRedirectUrl = makeUrlFromLearnerPathFn(
  facebookAuthRedirectPath
);

export const learnerAppleAuthRedirectUrl = makeUrlFromLearnerPathFn(
  appleAuthRedirectPath
);

export const learnerOidcAuthLoginRedirectUrl = makeUrlFromLearnerPathFn(
  oidcAuthLoginRedirectPath
);

export const learnerOidcAuthLoginRedirectToProviderUrl =
  makeUrlFromLearnerPathFn(oidcAuthLoginRedirectToProviderPath);

export const authCodeTransferUrl =
  makeUrlFromLearnerPathFn(authCodeTransferPath);
