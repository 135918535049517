import { CurrencyCode, UserLocale } from "@outschool/gql-backend-generated";

// The locales in which the app is supported
// Distinct from the UserLocale gql type, which represents
// the user's preferred locale for emails
export enum I18nLocale {
  En = "en",
  Ko = "ko",
  Ja = "ja",
  ZhTw = "zh-TW",
  Es = "es",
}
export const SUPPORTED_LOCALES = Object.values(I18nLocale);
export const EAST_ASIA_LOCALES = [
  I18nLocale.Ko,
  I18nLocale.Ja,
  I18nLocale.ZhTw,
];
export const BASE_LOCALE = I18nLocale.En;

export const EAST_ASIA_TIMEZONES = ["Asia/Seoul", "Asia/Tokyo", "Asia/Taipei"];

export const LATIN_AMERICA_TIMEZONES = [
  "America/Antigua",
  "America/Aruba",
  "America/Barbados",
  "America/Belize",
  "America/Bogota",
  "America/Buenos_Aires",
  "America/Cancun",
  "America/Caracas",
  "America/El_Salvador",
  "America/Guadeloupe",
  "America/Guyana",
  "America/Havana",
  "America/Jamaica",
  "America/Lima",
  "America/Martinique",
  "America/Mexico_City",
  "America/Montevideo",
  "America/Panama",
  "America/Santiago",
  "America/Santo_Domingo",
  "America/Sao_Paulo",
  "America/St_Johns",
  "America/Tegucigalpa",
];

export const EA_LATAM_START_HOUR = 7;
export const EA_LATAM_END_HOUR = 21;

export type CurrencyInfo = {
  code: CurrencyCode;
  symbol: string;
  name: string;
  precision: number;
  disclaimer?: string;
  defaultLocales: I18nLocale[];
};

export type FlagInfo = {
  imageAlt: string;
  code: string;
};

export type CountryInfo = {
  currency: CurrencyInfo;
  flag: FlagInfo;
  code: CountryCode;
};

type CountryInfoMap = {
  [countryCode in CountryCode]: CountryInfo;
};

type CurrencyInfoMap = {
  [currencyCode in CurrencyCode]: CurrencyInfo;
};

export enum CountryCode {
  US = "US",
  GB = "GB",
  CA = "CA",
  AU = "AU",
  NZ = "NZ",
  KR = "KR",
  TH = "TH",
  IN = "IN",
  TW = "TW",
  JP = "JP",
  HK = "HK",
  MX = "MX",
  EU = "EU",
}

export const CURRENCIES_INFO: CurrencyInfoMap = {
  [CurrencyCode.Usd]: {
    code: CurrencyCode.Usd,
    symbol: "$",
    precision: 2,
    name: "United States Dollar",
    defaultLocales: [I18nLocale.En, I18nLocale.ZhTw],
  },
  [CurrencyCode.Gbp]: {
    code: CurrencyCode.Gbp,
    symbol: "£",
    name: "Pound Sterling",
    precision: 2,
    disclaimer:
      "The price in pounds is for reference only. Outschool will charge your financial institution the USD amount.",
    defaultLocales: [],
  },
  [CurrencyCode.Cad]: {
    code: CurrencyCode.Cad,
    symbol: "$",
    name: "Canadian Dollar",
    precision: 2,
    disclaimer:
      "The price in Canadian Dollars is for reference only. Outschool will charge your financial institution the USD amount.",
    defaultLocales: [],
  },
  [CurrencyCode.Aud]: {
    code: CurrencyCode.Aud,
    symbol: "$",
    name: "Australian Dollar",
    precision: 2,
    disclaimer:
      "The price in Australian Dollars is for reference only. Outschool will charge your financial institution the USD amount.",
    defaultLocales: [],
  },
  [CurrencyCode.Nzd]: {
    code: CurrencyCode.Nzd,
    symbol: "$",
    name: "New Zealand Dollar",
    precision: 2,
    disclaimer:
      "The price in New Zealand Dollars is for reference only. Outschool will charge your financial institution the USD amount.",
    defaultLocales: [],
  },
  [CurrencyCode.Krw]: {
    code: CurrencyCode.Krw,
    symbol: "₩",
    name: "Korean Republic Won",
    precision: 0,
    disclaimer:
      "The price in Korean Republic Won is for reference only. Outschool will charge your financial institution the USD amount.",
    defaultLocales: [I18nLocale.Ko],
  },
  [CurrencyCode.Thb]: {
    code: CurrencyCode.Thb,
    symbol: "฿",
    name: "Thai Baht",
    precision: 2,
    disclaimer:
      "The price in Thai Baht is for reference only. Outschool will charge your financial institution the USD amount.",
    defaultLocales: [],
  },
  [CurrencyCode.Inr]: {
    code: CurrencyCode.Inr,
    symbol: "₹",
    name: "Indian Rupee",
    precision: 2,
    disclaimer:
      "The price in Indian Rupees is for reference only. Outschool will charge your financial institution the USD amount.",
    defaultLocales: [],
  },
  [CurrencyCode.Twd]: {
    code: CurrencyCode.Twd,
    symbol: "$",
    name: "New Taiwan Dollar",
    precision: 2,
    disclaimer:
      "The price in New Taiwan Dollars is for reference only. Outschool will charge your financial institution the USD amount.",
    // can't be the default currency for zh-TW because zh-TW is also used for Hong Kong
    defaultLocales: [],
  },
  [CurrencyCode.Jpy]: {
    code: CurrencyCode.Jpy,
    symbol: "¥",
    precision: 0,
    name: "Japanese Yen",
    disclaimer:
      "The price in Japanese Yen is for reference only. Outschool will charge your financial institution the USD amount.",
    defaultLocales: [I18nLocale.Ja],
  },
  [CurrencyCode.Hkd]: {
    code: CurrencyCode.Hkd,
    symbol: "$",
    name: "Hong Kong Dollar",
    precision: 2,
    disclaimer:
      "The price in Hong Kong Dollars is for reference only. Outschool will charge your financial institution the USD amount.",
    defaultLocales: [],
  },
  [CurrencyCode.Mxn]: {
    code: CurrencyCode.Mxn,
    symbol: "$",
    name: "Mexican Peso",
    precision: 2,
    disclaimer:
      "The price in Mexican Pesos is for reference only. Outschool will charge your financial institution the USD amount.",
    defaultLocales: [],
  },
  [CurrencyCode.Eur]: {
    code: CurrencyCode.Eur,
    symbol: "€",
    name: "Euro",
    precision: 2,
    disclaimer:
      "The price in Euros is for reference only. Outschool will charge your financial institution the USD amount.",
    defaultLocales: [],
  },
};

export const BASE_CURRENCY = CURRENCIES_INFO[CurrencyCode.Usd];
export const USD_STATIC_EXCHANGE_RATE = 1;
export const BASE_FREE_PRICE = "FREE";

// Fun fact: there are two in use currencies in the world that don't have a
// base-10 currency: the Mauritanian ouguiya (MRU) and Malagasy ariary (MGA) both
// use base-5.
// Until we have to support Madagascar and Mauritania, let's keep this simple.
export const STANDARD_CURRENCY_RADIX = 10;

export const COUNTRIES_INFO: CountryInfoMap = {
  [CountryCode.US]: {
    currency: CURRENCIES_INFO[CurrencyCode.Usd],
    flag: {
      imageAlt: "Flag of the United States",
      code: "US",
    },
    code: CountryCode.US,
  },
  [CountryCode.GB]: {
    currency: CURRENCIES_INFO[CurrencyCode.Gbp],
    flag: {
      imageAlt: "Flag of the United Kingdom",
      code: "GB",
    },
    code: CountryCode.GB,
  },
  [CountryCode.CA]: {
    currency: CURRENCIES_INFO[CurrencyCode.Cad],
    flag: {
      imageAlt: "Flag of Canada",
      code: "CA",
    },
    code: CountryCode.CA,
  },
  [CountryCode.AU]: {
    currency: CURRENCIES_INFO[CurrencyCode.Aud],
    flag: {
      imageAlt: "Flag of Australia",
      code: "AU",
    },
    code: CountryCode.AU,
  },
  [CountryCode.NZ]: {
    currency: CURRENCIES_INFO[CurrencyCode.Nzd],
    flag: {
      imageAlt: "Flag of New Zealand",
      code: "NZ",
    },
    code: CountryCode.NZ,
  },
  [CountryCode.KR]: {
    currency: CURRENCIES_INFO[CurrencyCode.Krw],
    flag: {
      imageAlt: "Flag of the Republic of Korea",
      code: "KR",
    },
    code: CountryCode.KR,
  },
  [CountryCode.TH]: {
    currency: CURRENCIES_INFO[CurrencyCode.Thb],
    flag: {
      imageAlt: "Flag of Thailand",
      code: "TH",
    },
    code: CountryCode.TH,
  },
  [CountryCode.IN]: {
    currency: CURRENCIES_INFO[CurrencyCode.Inr],
    flag: {
      imageAlt: "Flag of India",
      code: "IN",
    },
    code: CountryCode.IN,
  },
  [CountryCode.TW]: {
    currency: CURRENCIES_INFO[CurrencyCode.Twd],
    flag: {
      imageAlt: "Flag of Taiwan",
      code: "TW",
    },
    code: CountryCode.TW,
  },
  [CountryCode.JP]: {
    currency: CURRENCIES_INFO[CurrencyCode.Jpy],
    flag: {
      imageAlt: "Flag of Japan",
      code: "JP",
    },
    code: CountryCode.JP,
  },
  [CountryCode.HK]: {
    currency: CURRENCIES_INFO[CurrencyCode.Hkd],
    flag: {
      imageAlt: "Flag of Hong Kong",
      code: "HK",
    },
    code: CountryCode.HK,
  },
  [CountryCode.MX]: {
    currency: CURRENCIES_INFO[CurrencyCode.Mxn],
    flag: {
      imageAlt: "Flag of Mexico",
      code: "MX",
    },
    code: CountryCode.MX,
  },
  [CountryCode.EU]: {
    currency: CURRENCIES_INFO[CurrencyCode.Eur],
    flag: {
      imageAlt: "Flag of The European Union",
      code: "EU",
    },
    code: CountryCode.EU,
  },
};

export const ACTIVELY_CHARGED_CURRENCIES = [
  CurrencyCode.Usd,
  CurrencyCode.Cad,
  CurrencyCode.Gbp,
  CurrencyCode.Jpy,
  CurrencyCode.Krw,
  CurrencyCode.Aud,
  CurrencyCode.Hkd,
  CurrencyCode.Twd,
  CurrencyCode.Eur,
];

export type SupportedLanguage = {
  i18nLocale: I18nLocale;
  userLocale: UserLocale;
  localLanguageName: string;
};

export const SUPPORTED_LANGUAGES: SupportedLanguage[] = [
  {
    // English
    i18nLocale: I18nLocale.En,
    userLocale: UserLocale.En,
    localLanguageName: "English",
  },
  {
    // Korean
    i18nLocale: I18nLocale.Ko,
    userLocale: UserLocale.Ko,
    localLanguageName: "한국어",
  },
  {
    // Japanese
    i18nLocale: I18nLocale.Ja,
    userLocale: UserLocale.Ja,
    localLanguageName: "日本語",
  },
  {
    // Chinese (Traditional)
    i18nLocale: I18nLocale.ZhTw,
    userLocale: UserLocale.ZhTw,
    localLanguageName: "國語(繁體)",
  },
  {
    // Spanish (Latin America)
    i18nLocale: I18nLocale.Es,
    userLocale: UserLocale.Es,
    localLanguageName: "Español",
  },
];

// Query param key used to configure the page locale
export const LOCALE_QUERY_PARAM_KEY = "locale";

export type CountryOfResidence = {
  code: "us" | "gb" | "ca" | "au" | "nz" | "in" | "kr" | "mx" | "es";
  name: string;
  icon: string;
  prefix?: string;
  hidden?: boolean;
};

export const COUNTRIES_OF_RESIDENCE: CountryOfResidence[] = [
  {
    code: "us",
    name: "United States",
    icon: "🇺🇸",
    prefix: "the",
  },
  {
    code: "gb",
    name: "United Kingdom",
    icon: "🇬🇧",
    prefix: "the",
  },
  {
    code: "ca",
    name: "Canada",
    icon: "🇨🇦",
  },
  {
    code: "au",
    name: "Australia",
    icon: "🇦🇺",
  },
  {
    code: "nz",
    name: "New Zealand",
    icon: "🇳🇿",
  },
  {
    code: "in",
    name: "India",
    icon: "🇮🇳",
    // India is a hidden option, meaning the user cannot select this themselves.
    // An admin must set this manually for it to appear.
    hidden: true,
  },
  {
    code: "kr",
    name: "South Korea",
    icon: "🇰🇷",
  },
  {
    code: "es",
    name: "Spain",
    icon: "🇪🇸",
  },
  {
    code: "mx",
    name: "Mexico",
    icon: "🇲🇽",
  },
];

export const LineLocales = [I18nLocale.Ja, I18nLocale.ZhTw];

export const KakaoLocales = [I18nLocale.Ko];

export type ISOCountryCodeKey = keyof typeof ISO_COUNTRY_CODE_TO_CURRENCY_CODE;

type ISOCountryCodeToCurrencyMap = { [isoCountryCode: string]: CurrencyCode };

// Mapping from ISO country code to currency code based on this spreadsheet provided by Nina Anziska:
// https://docs.google.com/spreadsheets/d/1f5bIug5Pd3vTJHEHW_cGtlIvE6a-2BR0CS5QOgJnu_4/edit?gid=818132115#gid=818132115
export const ISO_COUNTRY_CODE_TO_CURRENCY_CODE: ISOCountryCodeToCurrencyMap = {
  // United States Dollar
  ["AE"]: CurrencyCode.Usd,
  ["AF"]: CurrencyCode.Usd,
  ["AG"]: CurrencyCode.Usd,
  ["AI"]: CurrencyCode.Usd,
  ["AL"]: CurrencyCode.Usd,
  ["AM"]: CurrencyCode.Usd,
  ["AN"]: CurrencyCode.Usd,
  ["AO"]: CurrencyCode.Usd,
  ["AQ"]: CurrencyCode.Usd,
  ["AR"]: CurrencyCode.Usd,
  ["AW"]: CurrencyCode.Usd,
  ["AZ"]: CurrencyCode.Usd,
  ["BA"]: CurrencyCode.Usd,
  ["BB"]: CurrencyCode.Usd,
  ["BD"]: CurrencyCode.Usd,
  ["BF"]: CurrencyCode.Usd,
  ["BG"]: CurrencyCode.Usd,
  ["BH"]: CurrencyCode.Usd,
  ["BI"]: CurrencyCode.Usd,
  ["BJ"]: CurrencyCode.Usd,
  ["BM"]: CurrencyCode.Usd,
  ["BN"]: CurrencyCode.Usd,
  ["BO"]: CurrencyCode.Usd,
  ["BR"]: CurrencyCode.Usd,
  ["BS"]: CurrencyCode.Usd,
  ["BV"]: CurrencyCode.Usd,
  ["BW"]: CurrencyCode.Usd,
  ["BY"]: CurrencyCode.Usd,
  ["BZ"]: CurrencyCode.Usd,
  ["CD"]: CurrencyCode.Usd,
  ["CF"]: CurrencyCode.Usd,
  ["CG"]: CurrencyCode.Usd,
  ["CH"]: CurrencyCode.Usd,
  ["CI"]: CurrencyCode.Usd,
  ["CL"]: CurrencyCode.Usd,
  ["CM"]: CurrencyCode.Usd,
  ["CN"]: CurrencyCode.Usd,
  ["CO"]: CurrencyCode.Usd,
  ["CR"]: CurrencyCode.Usd,
  ["CU"]: CurrencyCode.Usd,
  ["CV"]: CurrencyCode.Usd,
  ["CY"]: CurrencyCode.Usd,
  ["CZ"]: CurrencyCode.Usd,
  ["DJ"]: CurrencyCode.Usd,
  ["DK"]: CurrencyCode.Usd,
  ["DM"]: CurrencyCode.Usd,
  ["DO"]: CurrencyCode.Usd,
  ["DZ"]: CurrencyCode.Usd,
  ["EC"]: CurrencyCode.Usd,
  ["EE"]: CurrencyCode.Usd,
  ["EG"]: CurrencyCode.Usd,
  ["EH"]: CurrencyCode.Usd,
  ["ER"]: CurrencyCode.Usd,
  ["ET"]: CurrencyCode.Usd,
  ["FJ"]: CurrencyCode.Usd,
  ["FK"]: CurrencyCode.Usd,
  ["FM"]: CurrencyCode.Usd,
  ["FO"]: CurrencyCode.Usd,
  ["GA"]: CurrencyCode.Usd,
  ["GD"]: CurrencyCode.Usd,
  ["GE"]: CurrencyCode.Usd,
  ["GG"]: CurrencyCode.Usd,
  ["GH"]: CurrencyCode.Usd,
  ["GI"]: CurrencyCode.Usd,
  ["GL"]: CurrencyCode.Usd,
  ["GM"]: CurrencyCode.Usd,
  ["GN"]: CurrencyCode.Usd,
  ["GQ"]: CurrencyCode.Usd,
  ["GT"]: CurrencyCode.Usd,
  ["GU"]: CurrencyCode.Usd,
  ["GW"]: CurrencyCode.Usd,
  ["GY"]: CurrencyCode.Usd,
  ["HN"]: CurrencyCode.Usd,
  ["HR"]: CurrencyCode.Usd,
  ["HT"]: CurrencyCode.Usd,
  ["HU"]: CurrencyCode.Usd,
  ["ID"]: CurrencyCode.Usd,
  ["IL"]: CurrencyCode.Usd,
  ["IO"]: CurrencyCode.Usd,
  ["IQ"]: CurrencyCode.Usd,
  ["IR"]: CurrencyCode.Usd,
  ["IS"]: CurrencyCode.Usd,
  ["JM"]: CurrencyCode.Usd,
  ["JO"]: CurrencyCode.Usd,
  ["KE"]: CurrencyCode.Usd,
  ["KG"]: CurrencyCode.Usd,
  ["KH"]: CurrencyCode.Usd,
  ["KM"]: CurrencyCode.Usd,
  ["KN"]: CurrencyCode.Usd,
  ["KP"]: CurrencyCode.Usd,
  ["KW"]: CurrencyCode.Usd,
  ["KY"]: CurrencyCode.Usd,
  ["KZ"]: CurrencyCode.Usd,
  ["LA"]: CurrencyCode.Usd,
  ["LB"]: CurrencyCode.Usd,
  ["LC"]: CurrencyCode.Usd,
  ["LI"]: CurrencyCode.Usd,
  ["LK"]: CurrencyCode.Usd,
  ["LR"]: CurrencyCode.Usd,
  ["LS"]: CurrencyCode.Usd,
  ["LT"]: CurrencyCode.Usd,
  ["LV"]: CurrencyCode.Usd,
  ["LY"]: CurrencyCode.Usd,
  ["MA"]: CurrencyCode.Usd,
  ["MD"]: CurrencyCode.Usd,
  ["MF"]: CurrencyCode.Usd,
  ["MG"]: CurrencyCode.Usd,
  ["MH"]: CurrencyCode.Usd,
  ["MK"]: CurrencyCode.Usd,
  ["ML"]: CurrencyCode.Usd,
  ["MM"]: CurrencyCode.Usd,
  ["MN"]: CurrencyCode.Usd,
  ["MO"]: CurrencyCode.Usd,
  ["MP"]: CurrencyCode.Usd,
  ["MR"]: CurrencyCode.Usd,
  ["MS"]: CurrencyCode.Usd,
  ["MT"]: CurrencyCode.Usd,
  ["MU"]: CurrencyCode.Usd,
  ["MV"]: CurrencyCode.Usd,
  ["MW"]: CurrencyCode.Usd,
  ["MY"]: CurrencyCode.Usd,
  ["MZ"]: CurrencyCode.Usd,
  ["NA"]: CurrencyCode.Usd,
  ["NC"]: CurrencyCode.Usd,
  ["NE"]: CurrencyCode.Usd,
  ["NG"]: CurrencyCode.Usd,
  ["NI"]: CurrencyCode.Usd,
  ["NO"]: CurrencyCode.Usd,
  ["NP"]: CurrencyCode.Usd,
  ["OM"]: CurrencyCode.Usd,
  ["PA"]: CurrencyCode.Usd,
  ["PE"]: CurrencyCode.Usd,
  ["PF"]: CurrencyCode.Usd,
  ["PG"]: CurrencyCode.Usd,
  ["PH"]: CurrencyCode.Usd,
  ["PK"]: CurrencyCode.Usd,
  ["PL"]: CurrencyCode.Usd,
  ["PR"]: CurrencyCode.Usd,
  ["PS"]: CurrencyCode.Usd,
  ["PW"]: CurrencyCode.Usd,
  ["PY"]: CurrencyCode.Usd,
  ["QA"]: CurrencyCode.Usd,
  ["RO"]: CurrencyCode.Usd,
  ["RS"]: CurrencyCode.Usd,
  ["RU"]: CurrencyCode.Usd,
  ["RW"]: CurrencyCode.Usd,
  ["SA"]: CurrencyCode.Usd,
  ["SB"]: CurrencyCode.Usd,
  ["SC"]: CurrencyCode.Usd,
  ["SD"]: CurrencyCode.Usd,
  ["SE"]: CurrencyCode.Usd,
  ["SG"]: CurrencyCode.Usd,
  ["SJ"]: CurrencyCode.Usd,
  ["SK"]: CurrencyCode.Usd,
  ["SL"]: CurrencyCode.Usd,
  ["SN"]: CurrencyCode.Usd,
  ["SO"]: CurrencyCode.Usd,
  ["SR"]: CurrencyCode.Usd,
  ["ST"]: CurrencyCode.Usd,
  ["SV"]: CurrencyCode.Usd,
  ["SY"]: CurrencyCode.Usd,
  ["SZ"]: CurrencyCode.Usd,
  ["TC"]: CurrencyCode.Usd,
  ["TD"]: CurrencyCode.Usd,
  ["TG"]: CurrencyCode.Usd,
  ["TJ"]: CurrencyCode.Usd,
  ["TM"]: CurrencyCode.Usd,
  ["TN"]: CurrencyCode.Usd,
  ["TO"]: CurrencyCode.Usd,
  ["TP"]: CurrencyCode.Usd,
  ["TR"]: CurrencyCode.Usd,
  ["TT"]: CurrencyCode.Usd,
  ["TZ"]: CurrencyCode.Usd,
  ["UA"]: CurrencyCode.Usd,
  ["UG"]: CurrencyCode.Usd,
  ["UM"]: CurrencyCode.Usd,
  ["US"]: CurrencyCode.Usd,
  ["USAF"]: CurrencyCode.Usd,
  ["UY"]: CurrencyCode.Usd,
  ["UZ"]: CurrencyCode.Usd,
  ["VC"]: CurrencyCode.Usd,
  ["VE"]: CurrencyCode.Usd,
  ["VG"]: CurrencyCode.Usd,
  ["VI"]: CurrencyCode.Usd,
  ["VN"]: CurrencyCode.Usd,
  ["VU"]: CurrencyCode.Usd,
  ["WF"]: CurrencyCode.Usd,
  ["YE"]: CurrencyCode.Usd,
  ["ZA"]: CurrencyCode.Usd,
  ["ZM"]: CurrencyCode.Usd,
  ["ZW"]: CurrencyCode.Usd,
  // Pound Sterling:
  ["GB"]: CurrencyCode.Gbp,
  ["GS"]: CurrencyCode.Gbp,
  ["IM"]: CurrencyCode.Gbp,
  ["JE"]: CurrencyCode.Gbp,
  ["SH"]: CurrencyCode.Gbp,
  // Canadian Dollar:
  ["CA"]: CurrencyCode.Cad,
  // Australian Dollar:
  ["AU"]: CurrencyCode.Aud,
  ["CC"]: CurrencyCode.Aud,
  ["CX"]: CurrencyCode.Aud,
  ["HM"]: CurrencyCode.Aud,
  ["KI"]: CurrencyCode.Aud,
  ["NF"]: CurrencyCode.Aud,
  ["NR"]: CurrencyCode.Aud,
  ["TV"]: CurrencyCode.Aud,
  // New Zealand Dollar:
  ["CK"]: CurrencyCode.Nzd,
  ["NU"]: CurrencyCode.Nzd,
  ["NZ"]: CurrencyCode.Nzd,
  ["PN"]: CurrencyCode.Nzd,
  ["TK"]: CurrencyCode.Nzd,
  // Korean Republic Won:
  ["KR"]: CurrencyCode.Krw,
  // Thai Baht:
  ["TH"]: CurrencyCode.Thb,
  // Indian Ruppe:
  ["BT"]: CurrencyCode.Inr,
  ["IN"]: CurrencyCode.Inr,
  // New Taiwan Dollar:
  ["TW"]: CurrencyCode.Twd,
  // Japanese Yen:
  ["JP"]: CurrencyCode.Jpy,
  // Hong Kong Dollar:
  ["HK"]: CurrencyCode.Hkd,
  // Mexican Peso:
  ["MX"]: CurrencyCode.Mxn,
  // Euro:
  ["AD"]: CurrencyCode.Eur,
  ["AS"]: CurrencyCode.Eur,
  ["AT"]: CurrencyCode.Eur,
  ["AX"]: CurrencyCode.Eur,
  ["BE"]: CurrencyCode.Eur,
  ["BL"]: CurrencyCode.Eur,
  ["DE"]: CurrencyCode.Eur,
  ["ES"]: CurrencyCode.Eur,
  ["FI"]: CurrencyCode.Eur,
  ["FR"]: CurrencyCode.Eur,
  ["GF"]: CurrencyCode.Eur,
  ["GP"]: CurrencyCode.Eur,
  ["GR"]: CurrencyCode.Eur,
  ["IE"]: CurrencyCode.Eur,
  ["IT"]: CurrencyCode.Eur,
  ["LU"]: CurrencyCode.Eur,
  ["MC"]: CurrencyCode.Eur,
  ["ME"]: CurrencyCode.Eur,
  ["MQ"]: CurrencyCode.Eur,
  ["NL"]: CurrencyCode.Eur,
  ["PM"]: CurrencyCode.Eur,
  ["PT"]: CurrencyCode.Eur,
  ["RE"]: CurrencyCode.Eur,
  ["SI"]: CurrencyCode.Eur,
  ["SM"]: CurrencyCode.Eur,
  ["TF"]: CurrencyCode.Eur,
  ["VA"]: CurrencyCode.Eur,
  ["WS"]: CurrencyCode.Eur,
  ["YT"]: CurrencyCode.Eur,
};
