import {
  CookieKeys,
  deserializeOsBot,
  isBotFromObject,
} from "@outschool/data-schemas";
import { isLocalStorageSupported } from "@outschool/local-storage";
import Cookies from "js-cookie";
import stringify from "json-stringify-safe";
import { validate as isUuid, v4 as uuid } from "uuid";

import pkg from "./pkg";

import type { OutschoolBotInfo } from "@outschool/data-schemas";
import type { City } from "@outschool/iplookup-client";
import type { AnalyticsEvent, EventProperties } from "../types";
import type { AnalyticsPlugin } from "../plugins";

const DEFAULT_TIMEOUT = 2500;
const DEVICE_UID_KEY = "outschool_device_uid";
const LOG_PREFIX = "@outschool/ui-analytics";

function createScriptElement(id: string, src: string): HTMLScriptElement {
  const el = document.createElement("script");

  el.setAttribute("id", id);
  el.setAttribute("src", src);
  el.setAttribute("type", "text/javascript");
  el.setAttribute("async", "true");

  return el;
}

function createImageElement(
  id: string,
  src: string,
  alt?: string
): HTMLImageElement {
  const el = document.createElement("img");
  const altText = `Tracking pixel for ${alt || id}`;

  el.setAttribute("id", id);
  el.setAttribute("src", src);
  el.setAttribute("alt", altText);
  el.setAttribute("width", "0");
  el.setAttribute("height", "0");
  el.setAttribute("border", "0");

  return el;
}

export function injectScript(
  id: string,
  src: string,
  timeout?: number
): Promise<boolean> {
  const loadTimeout =
    typeof timeout === "undefined" ? DEFAULT_TIMEOUT : timeout;
  let done = false;

  return new Promise((resolve, reject) => {
    try {
      if (document.getElementById(id)) {
        resolve(true);
        return;
      }

      window.setTimeout(() => {
        if (done) {
          return;
        }

        done = true;
        reject(new Error(`Timeout loading script with src: ${src}`));
      }, loadTimeout);

      const el = createScriptElement(id, src);

      el.onload = () => {
        if (done) {
          return;
        }

        done = true;
        resolve(true);
      };

      el.onerror = () => {
        if (done) {
          return;
        }

        done = true;
        reject(new Error(`Failed to include script with src: ${src}`));
      };

      document.body.appendChild(el);
    } catch (error) {
      done = true;
      reject(error);
    }
  });
}

export function injectImg(
  id: string,
  src: string,
  alt?: string,
  timeout?: number
): Promise<boolean> {
  const loadTimeout =
    typeof timeout === "undefined" ? DEFAULT_TIMEOUT : timeout;
  let done = false;

  return new Promise((resolve, reject) => {
    try {
      const foundImage = document.getElementById(id);

      if (!!foundImage) {
        foundImage.remove();
      }

      const el = createImageElement(id, src, alt);

      window.setTimeout(() => {
        if (done) {
          return;
        }

        done = true;
        reject(new Error(`Timeout loading pixel with src: ${src}`));
      }, loadTimeout);

      el.onload = () => {
        if (done) {
          return;
        }

        done = true;
        resolve(true);
      };

      el.onerror = () => {
        if (done) {
          return;
        }

        done = true;
        reject(new Error(`Failed to include pixel with src: ${src}`));
      };

      document.body.appendChild(el);
    } catch (error) {
      done = true;
      reject(error);
    }
  });
}

export function getDeviceUid(): undefined | string {
  const localStorage = isLocalStorageSupported();

  if (!localStorage) {
    return undefined;
  }

  const existingDeviceUid = localStorage.getItem(DEVICE_UID_KEY);
  if (!!existingDeviceUid && isUuid(existingDeviceUid)) {
    return existingDeviceUid;
  }
  const newDeviceUid = uuid();
  localStorage.setItem(DEVICE_UID_KEY, newDeviceUid);
  return newDeviceUid;
}

export type LocationInfo = {
  ip?: string;
  ip_country?: string;
  ip_subdivision?: string;
  ip_subdivisions?: string;
  ip_city?: string;
  ip_timezone?: string;
  is_in_european_union?: boolean;
};

export function formatLocationInfo(ipLookupInfo: City | null): LocationInfo {
  if (!ipLookupInfo) {
    return {};
  }

  const subdivisions = ipLookupInfo?.subdivisions || [];
  return {
    ip: ipLookupInfo?.traits?.ipAddress,
    ip_country: ipLookupInfo?.country?.isoCode,
    ip_subdivision: subdivisions?.[0]?.isoCode,
    ip_subdivisions:
      subdivisions?.map(sd => sd.isoCode)?.join(",") || undefined,
    ip_city: ipLookupInfo?.city?.names?.en,
    ip_timezone: ipLookupInfo?.location?.timeZone,
    is_in_european_union: ipLookupInfo?.country?.isInEuropeanUnion,
  };
}

export function logDebug(...args: Array<unknown>) {
  if (!pkg.debug) {
    return;
  }

  console.log(LOG_PREFIX, "|", ...args);
}

export function logDebugEvent(event: AnalyticsEvent) {
  if (!pkg.debug) {
    return;
  }

  const args: Array<unknown> = [event.type];

  if (event.event) {
    args.push("|", event.event);
  }

  args.push("\n  integrations: ", event.integrations);

  if (event.type === "identify") {
    args.push(
      "\n  anonymousId: ",
      event.anonymousId,
      "\n  userId: ",
      event.userId,
      "\n  traits: ",
      event.traits
    );
  } else {
    args.push("\n  properties: ", event.properties);
  }

  logDebug(...args);
}

export class AnalyticsError extends Error {
  error?: Error;

  constructor(message: string, error?: Error) {
    super(message);

    if (!!Error.captureStackTrace) {
      Error.captureStackTrace(this, AnalyticsError);
    }

    this.name = "AnalyticsError";

    if (!!error && error instanceof Error) {
      this.message += ` > ${error.message}`;
      this.error = error;
    }
  }
}

export class AnalyticsPluginError extends AnalyticsError {
  pluginLoaded: boolean;
  pluginName: string;
  pluginType: string;
  pluginVersion: string;

  constructor(plugin: AnalyticsPlugin, message: string, error?: Error) {
    super(message, error);

    if (!!Error.captureStackTrace) {
      Error.captureStackTrace(this, AnalyticsPluginError);
    }

    this.name = "AnalyticsPluginError";

    this.pluginLoaded = plugin.isLoaded();
    this.pluginName = plugin.name;
    this.pluginType = plugin.type;
    this.pluginVersion = plugin.version;
  }
}

/*
 * Some tracking libraries insist on the format of `<dollars>.<cents>` even
 * if the currency is not USD. For example: `10.00`, `10.90`, `10.99`.
 */
export function priceToDollarsAndCents(value: number): string {
  return Number(value || 0).toFixed(2);
}

/*
 * Check @outschool/data-schemas for implementation details.
 */
export function getBotRequestInfo(): OutschoolBotInfo {
  const osBotString = Cookies.get(CookieKeys.OsBot);
  const osBot = deserializeOsBot(osBotString || "");

  if (pkg.devMode === "bot") {
    osBot.is_bot = true;

    return osBot;
  }

  return osBot;
}

export function isBotRequest(): boolean {
  const osBot = getBotRequestInfo();

  return isBotFromObject(osBot);
}

export function cloneEventProperties(
  properties?: EventProperties
): EventProperties {
  if (!properties) {
    return {};
  }

  return JSON.parse(stringify(properties));
}

export function setLocalStorageItemWithTTL(
  key: string,
  value: string,
  ttl: number
) {
  if (!window?.localStorage) {
    return;
  }

  const now = Date.now();
  const item = {
    value: value,
    expiry: now + ttl, // ttl in milliseconds
  };

  window.localStorage.setItem(key, JSON.stringify(item));
}

export function getLocalStorageItemWithTTL(key: string): string | undefined {
  if (!window?.localStorage) {
    return;
  }

  const itemStr = window.localStorage.getItem(key);
  if (!itemStr) {
    return;
  }

  const item = JSON.parse(itemStr) as { value: string; expiry: number };
  const now = Date.now();

  if (now > item.expiry) {
    window.localStorage.removeItem(key); // Remove expired item
    return;
  }
  return item.value;
}
