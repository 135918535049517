import {
  Box,
  Image,
  List,
  ListItem,
  Modal,
  ModalContent,
  ModalCloseButton,
  Theme,
  Typography,
} from "@outschool/backpack";
import { useTranslation } from "@outschool/localization";
import { joinMembershipPath } from "@outschool/routes";
import { TrackedButton } from "@outschool/ui-components-shared";
import { useNavigation } from "@outschool/ui-utils";
import { useTrackEvent } from "@outschool/ui-analytics";
import React from "react";

import SubscriptionMeetImg from "../../images/membership_promo_desktop.png";
import MobileSubscriptionMeetingImg from "../../images/membership_promo_mobile.png";

export const COOKIE_SEEN_JOIN_SUBSCRIPTION = "os-join-subscription-seen";

export enum JoinSubscriptionModalTrackingEventSource {
  SectionEnroll = "section_enroll",
  Enroll = "enroll",
  NavItem = "nav_item",
  CDPBanner = "cdp_banner",
}

export function JoinSubscriptionModal({
  isOpen,
  onClose,
  priceInCents,
  proceedToEnroll,
  eventSource,
}: {
  isOpen: boolean;
  onClose: () => void;
  priceInCents?: number;
  proceedToEnroll?: (t?: any) => void;
  eventSource?: JoinSubscriptionModalTrackingEventSource;
}) {
  const { t } = useTranslation(
    "ui-components-website\\Modals\\JoinSubscriptionModal"
  );
  const navigate = useNavigation();
  const showDiscount = priceInCents && priceInCents > 0;
  const trackEvent = useTrackEvent();

  React.useEffect(() => {
    if (isOpen) {
      trackEvent("subscription_join_modal_view", {
        eventSource,
      });
    }
  }, [trackEvent, eventSource, isOpen]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalContent
        sx={(theme: Theme) => ({
          maxWidth: "640px",
          maxHeight: "420px",
          padding: "0 0 0 0",
          position: "relative",
          [theme.breakpoints.down("sm")]: {
            maxHeight: "none",
            borderRadius: "0",
            marginTop: "0",
          },
        })}
      >
        <ModalCloseButton
          aria-label="close"
          onClick={onClose}
          size="large"
          variant="text"
          sx={{
            position: "absolute",
            top: "0.5rem",
            right: "0.5rem",
            margin: 0,
            color: "grey.900",
            fontSize: "2.5rem",
          }}
        />
        <Box
          sx={(theme: Theme) => ({
            display: "none",
            [theme.breakpoints.down("sm")]: {
              display: "block",
              paddingLeft: "0.8rem",
            },
          })}
        >
          <Image src={MobileSubscriptionMeetingImg} sx={{ width: "100%" }} />
        </Box>
        <Box
          sx={(theme: Theme) => ({
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingLeft: "2.4rem",
            [theme.breakpoints.down("sm")]: {
              paddingRight: "2.4rem",
            },
          })}
        >
          <Box
            flex={true}
            sx={{
              flexDirection: "column",
              paddingY: "2.4rem",
            }}
          >
            <Box>
              <Typography variant="display2" sx={{ marginBottom: "8px" }}>
                {t("Save up to 20% on every class!")}
              </Typography>
              <Typography variant="body1">{t("Members can book")}</Typography>
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{ fontStyle: "italic" }}
              >
                &nbsp;{t("any")}&nbsp;
              </Typography>
              <Typography variant="body1">
                {t("Outschool class while spending less and learning more:")}
              </Typography>
            </Box>
            <Box>
              <List
                sx={{
                  padding: "1.1rem 0",
                  listStyleType: "disc",
                  "& li": {
                    paddingX: "0rem",
                    paddingY: "0.2rem",
                    display: "list-item",
                    listStyleType: "disc",
                    listStylePosition: "inside",
                  },
                }}
              >
                <ListItem>
                  <Typography variant="body1">
                    {t("No Outschool fees—ever")}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">
                    {t("One plan for the whole family")}
                  </Typography>
                </ListItem>
              </List>
              <TrackedButton
                variant="contained"
                trackingName="subscription_join_modal_cta"
                onClick={(_e: React.MouseEvent<HTMLButtonElement>) => {
                  navigate(joinMembershipPath());
                }}
                sx={(theme: Theme) => ({
                  marginTop: "2rem",
                  paddingX: "2rem",
                  paddingY: "1.1rem",
                  [theme.breakpoints.down("sm")]: {
                    width: "100%",
                  },
                })}
              >
                {t("See How It Works")}
              </TrackedButton>
              {showDiscount && (
                <Box
                  sx={(theme: Theme) => ({
                    paddingTop: "2.7rem",
                    paddingBottom: "0rem",
                    [theme.breakpoints.down("sm")]: {
                      display: "flex",
                      justifyContent: "center",
                    },
                  })}
                >
                  <TrackedButton
                    variant="link"
                    trackingName="subscription_join_modal_no_thanks"
                    onClick={proceedToEnroll}
                  >
                    <Typography variant="body2" sx={{ fontWeight: "500" }}>
                      {t("No thanks, continue without Membership")}
                    </Typography>
                  </TrackedButton>
                </Box>
              )}
            </Box>
          </Box>
          <Box
            flex={true}
            sx={(theme: Theme) => ({
              justifyContent: "space-between",
              [theme.breakpoints.down("sm")]: {
                display: "none",
              },
            })}
          >
            <Box>
              <Image
                src={SubscriptionMeetImg}
                sx={{
                  maxHeight: "410px",
                }}
              />
            </Box>
          </Box>
        </Box>
      </ModalContent>
    </Modal>
  );
}
